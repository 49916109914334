<template>
  <div>
    <div class="dv_span">
      <span>裁片信息</span>
    </div>
    <div class="el_ckb">
      <el-checkbox-group :disabled="false" v-model="call">
        <el-checkbox
          style="max-width: 180px"
          class="vd_but"
          border
          v-show="item.isShow !== false"
          v-for="item in this.smplForm.smpl_combination_List1"
          :label="`${item.dv_comb_id}#${item.smpl_name}`"
          :key="item.dv_comb_id"
        >
          <el-tooltip class="item" effect="light" :content="item.smpl_name" placement="top-start">
            <span class="textContainers" style="overflow: hidden; text-overflow: ellipsis">
              {{ item.smpl_name.length >= 10 ? item.smpl_name.slice(0, 8) + '...' : item.smpl_name.slice(0, 8) }}</span
            >
          </el-tooltip>
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <div class="vd_but">
      <el-button type="primary" plain size="small" @click="addCut()">确定</el-button>
      <el-button type="danger" plain size="small" @click="allDel()">全部取消</el-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    smplForm: {
      type: Object,
      required: true
    }
  },
  computed: {
    // selections() {
    //   // if (!this.smplForm.smpl_accandcut_list || !this.smplForm.smpl_create_Sample) return [];
    //   // let smpl_accandcut_list = Array.from(this.smplForm.smpl_accandcut_list, ({ mtrl_no, mtrl_name, smpl_mtrl_id, mtrl_type,isShow }) => {
    //   //   return {
    //   //     mtrl_name: mtrl_no + mtrl_name,
    //   //     smpl_mtrl_id: smpl_mtrl_id,
    //   //     mtrl_type: mtrl_type,
    //   //     isShow: isShow === false ? isShow : true
    //   //   };
    //   // });
    //   // let smpl_create_Sample = Array.from(this.smplForm.smpl_create_Sample, ({ createNewMtrl, id, mtrlType,isShow }) => {
    //   //   return {
    //   //     mtrl_name: createNewMtrl,
    //   //     smpl_mtrl_id: id,
    //   //     mtrl_type: mtrlType,
    //   //     isShow: isShow === false ? isShow : true
    //   //   };
    //   // });
    //   // return smpl_accandcut_list.concat(smpl_create_Sample);
    // }
  },
  data() {
    return {
      call: []
    };
  },
  methods: {
    addCut() {
      this.$emit('confirmSelectData', this.call);
      // this.smplForm.smpl_combination_List.push({
      //   mtrl_name:this.call.split(','),
      //
      // })
    },
    hiddenSelectedData() {
      this.call.forEach(item => {
        let accandcutListIndex = this.smplForm.smpl_combination_List1.findIndex(
          salItem => `${salItem.dv_comb_id}#${salItem.smpl_name}` === item
        );
        if (accandcutListIndex !== -1) this.$set(this.smplForm.smpl_combination_List1[accandcutListIndex], 'isShow', false);
      });
      this.call = [];
    },
    allDel() {
      this.call = [];
    }
  }
};
</script>
<style scoped lang="scss">
.vd_but {
  margin: 10px 0px 10px 0px;
}
.dv_span {
  margin-bottom: 10px;
  font-weight: 510;
  font-size: 18px;
}
.el_ckb {
  height: 120px;
  overflow: auto;
  background: #ffffff;
  border: 2px solid #e4e7ed;
  padding: 10px;
}
</style>
