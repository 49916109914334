import topic from '../topic';
export const mstkAPI = {
  getMstks: topic.mstkTopic + '/get_mstks',
  getPushMstks9001: topic.mstkTopic + '/get_push_mstks_9001',
  updateScanById: topic.mstkTopic + '/update_scan_by_id',
  destroy_mstk_by_ids: topic.mstkTopic + '/destroy_mstk_by_ids',
  getMstkQrcds: topic.mstkTopic + '/get_mstk_qrcds',
  getAllMstks: topic.mstkTopic + '/get_all_mstks',
  editMstkUseNum: topic.mstkTopic + '/edit_mstk_use_num'
};
