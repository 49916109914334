<template>
  <div>
    <el-form :model="smplForm" ref="smplCut" :disabled="isShow" size="mini">
      <el-table
        :data="smplForm.smpl_cut_list"
        :summary-method="getSummaries"
        show-summary
        border
        @selection-change="handleSelectionChange1"
        class="el-table-two"
      >
        <el-table-column type="selection" width="48" align="center"></el-table-column>
        <el-table-column label="序号" width="60" align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span class="vg_deep_red">所用物料</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_cut_list.' + scope.$index + '.smpl_parent_value'"
                          :rules="[{ required: true }]">
              <!-- <el-input show-word-limit maxlength="30" v-model="smplForm.smpl_cut_list[scope.$index].smpl_parent_ids" placeholder="暂无所用物料"></el-input> -->
              <el-select
                @change="cutChange(scope)"
                v-if="flag"
                v-model="smplForm.smpl_cut_list[scope.$index].smpl_parent_value"
                placeholder="请选择"
              >
                <el-option v-for="item in cutOptions" :key="item.smpl_mtrl_id" :label="item.mtrl_no"
                           :value="item.smpl_mtrl_id">
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span class="vg_deep_red">裁片编号</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_cut_list.' + scope.$index + '.mtrl_no'"
                          :rules="[{ required: true }]">
              <el-input
                @change="mtrlNoChange(scope)"
                v-model="smplForm.smpl_cut_list[scope.$index].mtrl_no"
                @input="smplForm.smpl_cut_list[scope.$index].mtrl_no = helper.keepEngNum2T(smplForm.smpl_cut_list[scope.$index].mtrl_no)"
                maxlength="18"
                show-word-limit
                placeholder="暂无物料编号"
                :disabled="true"
              >
                <template slot="prepend" class="vd_smplNo">{{ smplForm.smpl_no }}@</template>
              </el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span class="vg_deep_red">裁片名称</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_cut_list.' + scope.$index + '.mtrl_name'"
                          :rules="[{ required: true }]">
              <el-input
                @change="mtrlNoChange1(scope)"
                v-model="smplForm.smpl_cut_list[scope.$index].mtrl_name"
                maxlength="30"
                show-word-limit
                placeholder="暂无裁片名称"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span class="vg_deep_red">数量</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'smpl_cut_list.' + scope.$index + '.mtrl_number'"
                          :rules="[{ required: true }]">
              <el-input
                v-model="smplForm.smpl_cut_list[scope.$index].mtrl_number"
                @input="
                  smplForm.smpl_cut_list[scope.$index].mtrl_number = helper.keepTNum(smplForm.smpl_cut_list[scope.$index].mtrl_number)
                "
                maxlength="2"
                show-word-limit
                placeholder="暂无"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
  </div>
</template>

<script>
import { post } from '@api/request';
import { smplAPI } from '@api/modules/smpl';

export default {
  name: 'SmplEditPrcsChild',
  props: {
    smplForm: {
      type: Object,
      required: true
    },
    isShow: Boolean
  },
  data() {
    return {
      selectionsList: [],
      mtrlTypeGroupOpt: [],
      flag: true,
      cutOptions: []
    };
  },
  watch: {
    'smplForm.smpl_mtrl_list'(newValue, oldValue) {
      this.groupOptions();
    },
    'smplForm.smpl_plmtrl_list'(newValue, oldValue) {
      this.groupOptions();
    }
  },
  created() {
    this.initData();
  },
  methods: {
    // 保存裁片信息
    submit1() {
      // this.$confirm('确定保存', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // })
      //   .then(() => {
      //     this.$refs['smplCut'].validate(valid => {
      //       if (valid) {
      this.saveInfo1();
      //     } else {
      //       console.log('error submit!');
      //       return false;
      //     }
      //   });
      // })
      // .catch(() => {});
    },
    // 保存
    saveInfo1() {
      try {
        let { smpl_id, smpl_cut_list, smpl_no } = this.smplForm;
        let requestBoby = {};
        requestBoby.smpl_id = smpl_id;
        requestBoby.smpl_cut_list = smpl_cut_list;
        requestBoby.smpl_no = smpl_no;
        requestBoby.cutCopy = requestBoby.smpl_cut_list.filter(item =>
          requestBoby.smpl_cut_list.every(item1 => item.smpl_mtrl_id !== item1.smpl_mtrl_id)
        );
        for (let i = 0; i < requestBoby.cutCopy.length; i++) {
          requestBoby.cutCopy[i].destroy_flag = 1;
        }
        requestBoby.smpl_cut_list = requestBoby.smpl_cut_list.concat(requestBoby.cutCopy);
        // for (let i = 0; i < requestBoby.smpl_cut_list.length; i++) {
        //   requestBoby.smpl_cut_list[i].mtrl_no = requestBoby.smpl_no + '@' + requestBoby.smpl_cut_list[i].mtrl_no;
        // }
        post(smplAPI.editSmplCut, requestBoby)
          .then(res => {
            if (res.data.code === 0) {
              // this.$message({
              //   type: 'success',
              //   message: '保存成功'
              // });
            }
            // this.getsmplInfo(0);
          })
          .catch(err => {
            this.$message.error(err);
          });
        // this.$emit('refresh');
      } catch (e) {
        console.error(e);
      }
    },
    // 删除
    deleteInfo() {
      try {
        post(smplAPI.deleteSmplCut, {
          smpl_cut_list: this.selectionsList
        })
          .then(res => {
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '删除成功'
              });
              this.$emit('refresh');
            }
            // this.getsmplInfo(0);
          })
          .catch(err => {
            this.$message.error(err);
          });
      } catch (e) {
        console.error(e);
      }
    },
    // 裁片名称重复
    mtrlNoChange1(scope) {
      let mtrlFlag = 0;
      for (let i = 0; i < this.smplForm.smpl_cut_list.length; i++) {
        if (this.smplForm.smpl_cut_list[i].mtrl_name === scope.row.mtrl_name) {
          mtrlFlag += 1;
        }
      }
      if (mtrlFlag > 1) {
        scope.row.mtrl_name = null;
        this.$message.error('裁片名称重复，请重新填写！');
      } else {
        this.$emit('cutChangeCuts1', scope);
      }
    },
    getSummaries(params) {
      const { columns, data } = params;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === columns.length - 1) {
          const values = data.map(item => Number(item['mtrl_number']));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = '总数量: ' + sums[index];
          }
        }
      });
      return sums;
    },
    groupOptions() {
      // BOM
      let smplMtrlList = Array.from(this.smplForm.smpl_mtrl_list, ({ mtrl_no, mtrl_name, smpl_mtrl_id }) => ({
        mtrl_no: '' + mtrl_no + mtrl_name,
        smpl_mtrl_id: smpl_mtrl_id
      }));
      // 加工面料
      let smplPlmtrlList = Array.from(this.smplForm.smpl_plmtrl_list, ({ mtrl_no, mtrl_name, smpl_mtrl_id }) => ({
        mtrl_no: '' + mtrl_no + mtrl_name,
        smpl_mtrl_id: smpl_mtrl_id
      }));
      this.cutOptions = [];
      this.cutOptions = this.cutOptions.concat(smplMtrlList).concat(smplPlmtrlList);
    },
    initData() {
    },
    // 物料编号重复
    mtrlNoChange(scope) {
      let mtrlFlag = 0;
      for (let i = 0; i < this.smplForm.smpl_cut_list.length; i++) {
        if (this.smplForm.smpl_cut_list[i].mtrl_no === scope.row.mtrl_no) {
          mtrlFlag += 1;
        }
      }
      if (mtrlFlag > 1) {
        scope.row.mtrl_no = null;
        this.$message.error('物料编号重复，请重新填写！');
      } else {
        this.$emit('cutChangeCuts', scope);
      }
    },
    // 下拉框回显
    cutChange(scope) {
      this.flag = false;
      this.$emit('cutChangeIds', scope);
      this.$nextTick(() => {
        this.flag = true;
      });
    },
    //prcs清单选择框
    handleSelectionChange1(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit('handleSelectionChange1', this.selectionsList);
    }
  }
};
</script>

<style lang="scss">
.vd_dis {
  display: inline-block;
}

.vd_mrl {
  margin-left: 5px;
}

.vd_wid100 {
  width: 100%;
}

.el-input-group__prepend {
  font-size: 16px;
  color: #000000;
}
</style>
