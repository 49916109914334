<template>
  <div>
    <el-card>
      <div style="display: flex">
        <div style="width: 50%">
          <el-row>
            <el-col v-if="isShow">
              <el-button type="warning" @click="stprEdit" size="small" :disabled="editFlag || rfid_perm_status === 0"
                >编辑</el-button
              >
            </el-col>
            <el-col v-else>
              <el-button type="primary" @click="submit()" size="small">保存</el-button>
              <el-button @click="closeEdit" size="small">取消</el-button>
            </el-col>
          </el-row>
          <el-form ref="laseRef" :model="prop" label-width="120px" :rules="laseRules" :disabled="isShow" size="small">
            <el-row>
              <el-col :md="12">
                <el-form-item label="手裁用量：" prop="rfid_handcut_phr">
                  <el-input maxlength="9" show-word-limit :value="prop.rfid_handcut_phr" @input="rfid_handcut_phrChange">
                    <template slot="append">片</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :md="12">
                <el-form-item label="试样片数：" prop="sample_phr">
                  <el-input maxlength="9" show-word-limit :value="prop.sample_phr" @input="sample_phrChange">
                    <template slot="append">片</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :md="12">
                <el-form-item label="试样个数：" prop="sample_num">
                  <el-input maxlength="9" show-word-limit :value="prop.sample_num" @input="sample_numChange">
                    <template slot="append">个</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div>
          <el-form :disabled="true" label-width="100px" label="right">
            <el-row>
              <el-form-item label="制作人：" size="small">
                <el-input show-word-limit :value="prop.stff_name"> </el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="岗位：" size="small">
                <el-input show-word-limit :value="prop.rfid_handcut_post"> </el-input>
              </el-form-item>
            </el-row>
          </el-form>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { post } from '@api/request';
import { rfidAPI } from '@api/modules/rfid';
import helper from '@assets/js/helper';

export default {
  data() {
    return {
      isShow: true,
      laseRules: {
        rfid_handcut_phr: [
          { pattern: /^[0-9]*$/, message: '只能输入零和正整数!' },
          { required: true, message: '请填写手裁用量', trigger: true }
        ]
      }
    };
  },
  props: {
    prop: Object,
    rfid_perm_status: Number,
    rfid_Info: Object,
    editFlag: Boolean,
    originalhandCut: Object
  },
  methods: {
    stprEdit() {
      this.$emit('num');
      this.isShow = false;
    },
    submit() {
      this.$refs['laseRef'].validate(valid => {
        if (valid) {
          this.$confirm('此操作将保存修改, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(res => {
              let editBody = {};
              editBody = JSON.parse(JSON.stringify(this.prop));
              if (!editBody.rfid_id) {
                editBody.rfid_id = this.rfid_Info.rfid_id;
                editBody.user_id = this.rfid_Info.user_id;
                editBody.stff_name = this.rfid_Info.stff_name;
                editBody.stff_id = this.rfid_Info.stff_id;
              }
              post(rfidAPI.edit_handCut, editBody)
                .then(res => {
                  if (res.data.code === 0) {
                    this.$message.success(res.data.msg);
                    this.$emit('copyHand', this.prop);
                  } else {
                    this.$message.error(res.data.msg);
                  }

                  this.$emit('del');
                })
                .catch(res => {
                  this.$message.error(res.data.msg);
                });

              this.isShow = true;
            })
            .catch(res => {});
        }
      });
    },
    closeEdit() {
      this.$confirm('此操作将取消修改, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(res => {
          this.isShow = true;
          this.$message.info('已取消修改');
          this.$emit('del');
          this.prop.rfid_handcut_phr = this.originalhandCut.rfid_handcut_phr;
          this.prop.sample_phr = this.originalhandCut.sample_phr;
          this.prop.sample_num = this.originalhandCut.sample_num;
        })
        .catch(res => {});
    },
    rfid_handcut_phrChange(val) {
      val = this.keepNum99(val);
      this.$emit('rfid_handcut_phrChange', val);
    },
    sample_phrChange(val) {
      val = this.helper.keepTNum(val);
      this.$emit('sample_phrChange', val);
    },
    sample_numChange(val) {
      val = this.helper.keepTNum(val);
      this.$emit('sample_numChange', val);
    },
    keepNum99(val) {
      let keepTNum = Number(helper.keepTNum(val));
      if (keepTNum > 99) {
        this.$message.warning('输入范围0～99超出最大限度');
        return 99;
      } else {
        return keepTNum;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
}
</style>
