<template>
  <div>
    <el-card>
      <div style="display: flex">
        <div style="width: 50%">
          <el-form>
            <el-row>
              <el-col :md="24">
                <el-form-item label="备注：" label-width="80px" :rules="{ required: true }">
                  <el-input type="textarea" :disabled="true" v-model="prop.remark"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div>
          <el-form :disabled="true" label-width="100px" label="right">
            <el-row>
              <el-form-item label="制作人：" size="small">
                <el-input show-word-limit :value="prop.stff_name"> </el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="岗位：" size="small">
                <el-input show-word-limit :value="prop.user_jobs"> </el-input>
              </el-form-item>
            </el-row>
          </el-form>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: ['prop'],
  mounted() {
    // console.error(this.prop)
  }
};
</script>

<style lang="scss" scoped></style>
