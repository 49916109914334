<template>
  <div>
    <el-card>
      <div style="display: flex">
        <div style="width: 50%">
          <el-row>
            <el-col v-if="isShow">
              <el-button type="warning" @click="stprEdit" size="small" :disabled="editFlag || rfid_perm_status == 0 ? true : false"
                >编辑</el-button
              >
            </el-col>
            <el-col v-else>
              <el-button type="primary" @click="submit()" size="small">保存</el-button>
              <el-button @click="closeEdit" size="small">取消</el-button>
            </el-col>
          </el-row>
          <el-form ref="compRef" :model="prop" label-width="100px" :disabled="isShow" :rules="PropRules" size="small">
            <el-row>
              <el-col :md="12">
                <el-form-item label="复合数量：" prop="rfid_comp_phr">
                  <el-input maxlength="11" show-word-limit v-model="prop.rfid_comp_phr" @input="compPhrChange">
                    <template slot="append">米</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div>
          <el-form :disabled="true" label-width="100px" label="right">
            <el-row>
              <el-form-item label="制作人：" size="small">
                <el-input show-word-limit :value="prop.stff_name"> </el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="岗位：" size="small">
                <el-input show-word-limit :value="prop.user_jobs"> </el-input>
              </el-form-item>
            </el-row>
          </el-form>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { spocAPI } from '@api/modules/spoc';
import helper from '@assets/js/helper';
export default {
  data() {
    return {
      isShow: true,
      PropRules: {
        rfid_comp_phr: [
          {
            required: true,
            message: '请输入复合用量',
            trigger: 'blur'
          },
          {
            pattern: /^\d{0,6}(\.\d{1,4})?$/,
            message: '最大只能输入999999.9999且最多4位小数!'
          }
        ]
      }
    };
  },
  props: {
    prop: Object,
    rfid_perm_status: Number,
    rfid_Info: Object,
    editFlag: Boolean,
    originalComp: Object
  },
  methods: {
    btn() {
      console.log(this.prop.rfid_comp_phr);
    },
    submit() {
      this.$refs['compRef'].validate(valid => {
        if (valid) {
          this.$confirm('此操作将保存修改, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(res => {
              let editBody = {};
              editBody = JSON.parse(JSON.stringify(this.prop));
              if (!editBody.rfid_id) {
                editBody.rfid_id = this.rfid_Info.rfid_id;
                editBody.user_id = this.rfid_Info.user_id;
                editBody.stff_name = this.rfid_Info.stff_name;
                editBody.stff_id = this.rfid_Info.stff_id;
              }
              post(spocAPI.edit_comp, editBody)
                .then(res => {
                  if (res.data.code == 0) {
                    this.$message.success(res.data.msg);
                    //  this.originalComp.rfid_comp_phr =  this.prop.rfid_comp_phr
                    this.$emit('copyComp', this.prop);
                  } else {
                    this.$message.error(res.data.msg);
                  }
                  this.$emit('del');
                })
                .catch(res => {
                  this.$message.error(res.data.msg);
                });

              this.isShow = true;
            })
            .catch(res => {});
        }
      });
    },
    stprEdit() {
      this.$emit('num');
      this.isShow = false;
    },
    closeEdit() {
      this.$confirm('此操作将取消修改, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(res => {
          this.isShow = true;
          this.$message.info('已取消修改');
          this.$emit('del');
          this.prop.rfid_comp_phr = this.originalComp.rfid_comp_phr;
        })
        .catch(res => {});
    },
    compPhrChange(val) {
      val = this.keepNum99(val);
      this.$emit('compPhrChange', val);
    },
    keepNum99(val) {
      let keepTNum = helper.keepTNum1(val);
      if (keepTNum > 99) {
        this.$message.warning('输入范围0～99超出最大限度');
        return 99;
      } else {
        return keepTNum;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
}
</style>
