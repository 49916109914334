<template>
  <el-table :data="allTableData" stripe style="width: 100%">
    <el-table-column prop="mtrl_name" label="物料名称" width="auto"> </el-table-column>
    <el-table-column prop="mtrl_no" label="物料名称" width="auto"> </el-table-column>
    <el-table-column prop="mtrl_color" label="潘通色号" width="auto"> </el-table-column>
    <el-table-column prop="mtrl_class" label="颜色" width="auto"> </el-table-column>
    <el-table-column prop="smpl_mtrl_consume" label="用量" width="auto"> </el-table-column>
    <el-table-column prop="mtrl_spec" label="规格" width="auto"> </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: ['tableData'],
  computed: {
    allTableData() {
      return this.tableData;
    }
  },
  data() {
    return {
      tableData: []
    };
  }
};
</script>
