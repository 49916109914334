import topic from '../topic';
export const spocAPI = {
  edit_stpr: topic.spocTopic + '/edit_stpr', //
  edit_comp: topic.spocTopic + '/edit_comp', //
  edit_stam: topic.spocTopic + '/edit_stam', //
  edit_lase: topic.spocTopic + '/edit_lase', //
  edit_embr: topic.spocTopic + '/edit_embr', //
  edit_emcd: topic.spocTopic + '/edit_emcd', //
  edit_manu: topic.spocTopic + '/edit_manu', //
  edit_open: topic.spocTopic + '/edit_open', //
  edit_mase: topic.spocTopic + '/edit_mase' //
};
