<template>
  <div>
    <el-card>
      <div style="display: flex">
        <div style="width: 50%">
          <el-row>
            <el-col v-if="isShow">
              <el-button type="warning" @click="stprEdit" size="small" :disabled="editFlag || rfid_perm_status == 0 ? true : false"
                >编辑</el-button
              >
              <!-- rfid_perm_status 判断 当前登录账号是否有权限 0 没有权限 -->
            </el-col>
            <el-col v-else>
              <el-button type="primary" @click="submit()" size="small">保存</el-button>
              <el-button @click="closeEdit" size="small">取消</el-button>
            </el-col>
          </el-row>
          <el-form ref="stprRef" :model="prop" label-width="100px" :disabled="isShow">
            <el-row>
              <el-col :md="12">
                <el-form-item
                  label="备料数量："
                  prop="rfid_stpr_phr"
                  size="small"
                  :rules="{ request: true, message: '请输入备料数量', trigger: 'blur' }"
                >
                  <el-input maxlength="9" show-word-limit v-model="prop.rfid_stpr_phr" @input="stprPhrInp">
                    <template slot="append">个</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div>
          <el-form :disabled="true" label-width="100px" label="right">
            <el-row>
              <el-form-item label="制作人：" size="small">
                <el-input show-word-limit :value="prop.stff_name"> </el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="岗位：" size="small">
                <el-input show-word-limit :value="prop.user_jobs"> </el-input>
              </el-form-item>
            </el-row>
          </el-form>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { spocAPI } from '@api/modules/spoc';
export default {
  data() {
    return {
      stprForm: {},
      isShow: true
    };
  },
  props: {
    prop: {
      type: Object
    },
    rfid_perm_status: Number,
    rfid_Info: Object,
    editFlag: Boolean,
    originalStpr: Object
  },
  methods: {
    submit() {
      this.$confirm('此操作将保存修改, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(res => {
          let editBody = {};
          editBody = JSON.parse(JSON.stringify(this.prop));
          if (!editBody.rfid_id) {
            editBody.rfid_id = this.rfid_Info.rfid_id;
            editBody.user_id = this.rfid_Info.user_id;
            editBody.stff_name = this.rfid_Info.stff_name;
            editBody.stff_id = this.rfid_Info.stff_id;
          }
          post(spocAPI.edit_stpr, editBody)
            .then(res => {
              if (res.data.code == 0) {
                this.$message.success(res.data.msg);
                this.$emit('copyStpr', this.prop);
                this.originalStpr.rfid_stpr_phr = this.prop.rfid_stpr_phr;
              } else {
                this.$message.error(res.data.msg);
              }
              this.$emit('del');
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });

          this.isShow = true;
        })
        .catch(res => {});
    },
    stprEdit() {
      this.$emit('num');
      this.isShow = false;
    },
    closeEdit() {
      this.$confirm('此操作将取消修改, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(res => {
          this.isShow = true;
          this.$message.info('已取消修改');
          this.$emit('del');
          this.prop.rfid_stpr_phr = this.originalStpr.rfid_stpr_phr;
        })
        .catch(res => {});
    },
    stprPhrInp() {
      let val = this.helper.keepTNum(this.prop.rfid_stpr_phr);
      this.$emit('stprPhrInt', val);
    }
  },
  mounted() {
    this.$forceUpdate();
  }
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
}
</style>
