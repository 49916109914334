import topic from '../topic';

export const smplAPI = {
    getSmpls: topic.smplTopic + '/get_smpls',
    getSmplById: topic.smplTopic + '/get_smpl_by_id',
    editSmpl: topic.smplTopic + '/edit_smpl',
    deleteSmplByIds: topic.smplTopic + '/delete_smpl_by_ids',
    get_all_smpls_v1: topic.smplTopic + '/get_all_smpls_v1',
    judgeSmplNo: topic.smplTopic + '/judge_smpl_no',
    getSmplMtrlBySmplId: topic.smplTopic + '/get_smpl_mtrl_by_smpl_id',
    addSmplMail: topic.smplTopic + '/add_ship_info_for_smpls',
    getPushPostMails9003: topic.smplTopic + '/get_push_post_mail_9003', // 获取所有post的邮件情况
    updateScanById: topic.smplTopic + '/update_post_scan_by_id', // 重发的接口
    copyNewSmpl: topic.smplTopic + '/copy_new_smpl', // 同步样品
    getQutaSmplByRequId: topic.smplTopic + '/get_quta_smpl_by_requ_id',
    addCreateSample: topic.smplTopic + '/addCreateSample',
    editSmplCut: topic.smplTopic + '/editSmplCut', //裁片信息保存
    deleteSmplCut: topic.smplTopic + '/deleteSmplCut', //裁片信息删除
    deleteCreateSample: topic.smplTopic + '/deleteCreateSample', //生成部件信息删除
    getSmplPushList: topic.smplTopic + '/getSmplPushList',
    updateSmplPush: topic.smplTopic + '/updateSmplPush'
};
