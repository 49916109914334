import topic from '../topic';
export const openAPI = {
  mtrlToMes: topic.openTopic + '/api/mtrl_to_mes',
  smplToMes: topic.openTopic + '/api/smpl_to_mes',
  getMesMtrlByMtrlNo: topic.openTopic + '/api/get_mes_mtrl_by_mtrl_no',
  getMesProdBySmplNo: topic.openTopic + '/api/get_mes_prod_by_smpl_no',
  coverProdDocus: topic.openTopic + '/api/cover_prod_docus',
  coverMtrlDocus: topic.openTopic + '/api/cover_mtrl_docus',
  getCustFromErp: topic.openTopic + '/erp/api/get_cust_from_erp',
  getAllDeptsV1: topic.openTopic + '/erp/api/get_all_depts',
  getAllStffsV1: topic.openTopic + '/erp/api/get_all_stffs',
  getAllCpttsV1: topic.openTopic + '/erp/api/get_all_cptts',
  getOptnByPermId: topic.openTopic + '/erp/api/get_optn_from_erp',
  getAllInstsV1: topic.openTopic + '/erp/api/get_all_insts',
  addCust: topic.openTopic + '/erp/api/add_cust',
  getCusts: topic.openTopic + '/erp/api/get_custs',
  getCustById: topic.openTopic + '/erp/api/get_cust_by_id',
  editCust: topic.openTopic + '/erp/api/edit_cust'
};
