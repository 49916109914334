import topic from '../topic';
export const mtrlAPI = {
  getMtrls: topic.mtrlTopic + '/get_mtrls',
  getPlMtrls: topic.mtrlTopic + '/get_pl_mtrls',
  getMtrlsInclusive: topic.mtrlTopic + '/get_mtrls_inclusive',
  getMtrlById: topic.mtrlTopic + '/get_mtrl_by_id',
  addMtrl: topic.mtrlTopic + '/add_mtrl',
  editMtrl: topic.mtrlTopic + '/edit_mtrl',
  deleteMtrlByIds: topic.mtrlTopic + '/delete_mtrl_by_ids',
  getPushMtrls9002: topic.mtrlTopic + '/get_push_mtrls_9002',
  updateScanById: topic.mtrlTopic + '/update_scan_by_id',
  judgeMtrlNo: topic.mtrlTopic + '/judge_mtrl_no',
  getMtrlByInfo: topic.mtrlTopic + '/get_mtrl_by_info',
  getCountry: topic.mtrlTopic + '/get_country',
  editMtrlPriceByTerm: topic.mtrlTopic + '/edit_mtrl_price_by_term'
};
