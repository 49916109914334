<template>
  <div class="vg_wrapper">
    <publicTips></publicTips>
    <div class="vd_search_group">
      <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getMtrlsNow()">
        <el-row>
          <el-col :md="8">
            <el-form-item label="物料编号：">
              <el-input size="small" v-model.trim="searchForm.mtrl_no" clearable placeholder="请填写物料编号名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="物料名称：">
              <el-input size="small" v-model.trim="searchForm.mtrl_name" clearable placeholder="请填写物料名称"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getMtrlsNow()" class="vg_ml_16">查询</el-button>
              <el-button size="small" type="info" icon="el-icon-refresh" @click="getMtrlsNowO()" class="vg_ml_16">刷新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-row class="vd_mar15">
      <el-col :md="16">
        <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
        <el-button size="mini" type="primary" @click="confirmIn()">确认选择</el-button>
      </el-col>
    </el-row>
    <el-row>
      <div><i class="el-icon-info vg_mr_8"></i>{{ title }}</div>
    </el-row>
    <el-row class="vd_mrt">
      <el-col :md="24">
        <el-table
          ref="multiTable"
          :data="tableData"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
          border
          v-loading="loading"
        >
          <el-table-column type="selection" width="48" align="center" :reserve-selection="true" />
          <el-table-column label="物料编号" prop="mtrl_no" />
          <el-table-column label="物料名称" prop="mtrl_name" />
          <el-table-column label="单位" prop="mtrl_unit" />
          <el-table-column label="物料单价" prop="mtrl_price">
            <template slot-scope="scope">
              <div v-if="scope.row.mtrl_prod_price || scope.row.mtrl_prod_price === 0">
                {{ scope.row.mtrl_prod_price | formatMtrlPrice }}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <pubPagination ref="pubPagination" :totalPage="totalPage" @changePageSearch="changePageSearch"></pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mtrlAPI } from '@api/modules/mtrl';
import pubPagination from '@/components/common/pubPagination';
import publicTips from '@/views/component/publicTips';
import helper from '@assets/js/helper.js';

export default {
  name: 'SmplEditBom',
  components: {
    pubPagination,
    publicTips
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        mtrl_no: null,
        mtrl_name: null
      },
      totalPage: 0,
      btn: {},
      loading: true,
      multiSelection: [],
      currentPage: 1,
      mtrlType: [
        { id: 0, label: '原面料' },
        { id: 1, label: '辅料' },
        { id: 2, label: '包材' }
      ],
      title: '提示：相同数据仅限导入一条'
    };
  },
  created() {
    this.initData();
  },
  filters: {
    formatMtrlPrice(row) {
      return helper.haveFour(row);
    }
  },
  methods: {
    initData() {
      this.getMtrlsList();
    },
    // 获取物料信息
    getMtrlsList() {
      const searchBody = this.searchForm;
      searchBody.page_no = this.currentPage;
      // searchBody.status=2;
      get(mtrlAPI.getPlMtrls, searchBody)
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 查询方法
    getMtrlsNow() {
      this.$refs.pubPagination.currentPage = 1;
      this.loading = true;
      this.currentPage = 1;
      this.getMtrlsList();
    },
    // 刷新
    getMtrlsNowO() {
      this.$refs.pubPagination.currentPage = 1;
      this.loading = true;
      this.currentPage = 1;
      (this.searchForm = {
        mtrl_no: null,
        mtrl_name: null
      }),
        this.getMtrlsList();
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.mtrl_id;
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getMtrlsList();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('plmtrlChildChanel', false);
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length) {
        this.$emit('childPlmtrl', this.multiSelection);
      } else {
        this.$message({
          message: '至少选择一条信息',
          type: 'warning'
        });
      }
    },
    // 选未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
.vd_mrt {
  margin-top: 20px;
}
.vd_mar15 {
  margin: 15px 0;
}
.vd_col_font {
  font-size: 16px;
}
</style>
