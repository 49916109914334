<template>
  <div class="vg_wrapper">
    <publicTips></publicTips>
    <div class="vd_search_group">
      <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getMtrlsNow()">
        <el-row>
          <el-col :md="8">
            <el-form-item label="物料编号:">
              <el-input size="small" v-model.trim="searchForm.mtrl_no" clearable placeholder="请填写物料编号名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="物料名称:">
              <el-input size="small" v-model.trim="searchForm.mtrl_name" clearable placeholder="请填写物料名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <!-- 物料类型 暂时写死 等需要时打开  -->
            <el-form-item label="物料类型:">
              <el-select size="small" v-model="searchForm.mtrl_type" placeholder="请选择物料类型" clearable>
                <el-option v-for="item in mtrlType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="8">
            <el-form-item label="潘通色号:">
              <el-input size="small" v-model="searchForm.mtrl_color" clearable placeholder="请填写潘通色号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="颜色大类:">
              <el-select size="small" v-model="searchForm.colr_class" placeholder="请选择颜色大类" clearable filterable multiple>
                <el-option v-for="item in optnColorList" :key="item.param1" :label="item.param1" :value="item.param1"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-tag type="warning" class="vg_cursor" @click="recentlyImported" disable-transitions>最近导入</el-tag>
          </el-col>
        </el-row>
        <el-row class="vd_mar10">
          <el-col :md="8">
            <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
            <el-button size="mini" type="primary" @click="confirmIn()" class="vg_mr_8">确认选择</el-button>
            <!-- <el-link type="primary" @click="goMtrl" class="vg_cursor"><i class="el-icon-edit-outline"></i>物料信息表管理</el-link> -->
          </el-col>
          <el-col :md="16" class="disRight">
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getMtrlsNow()">查询</el-button>
              <el-button size="small" type="info" icon="el-icon-refresh" @click="getMtrlsNowO()" class="vg_ml_16">刷新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="24">
            <div v-if="multiSelection.length > 0">
              <div v-for="item in multiSelection" :key="item.mstk_id" style="display: inline-block">
                <el-tooltip class="item" effect="dark" :content="item.mtrl_no + '(' + item.mtrl_name + ')'" placement="top">
                  <el-tag class="vd_mgr2">
                    <div class="vd_dis_cen">
                      <div class="vd_tag">{{ item.mtrl_no }}({{ item.mtrl_name }})</div>
                      <i class="el-icon-circle-close vd_font" @click="delMulit(item.mstk_id)"></i>
                    </div>
                  </el-tag>
                </el-tooltip>
              </div>
            </div>
            <div v-else class="vd_vis">1111</div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-row>
      <div><i class="el-icon-info vg_mr_8"></i>{{ title }}</div>
    </el-row>
    <el-row class="vd_mrt">
      <el-col :md="24">
        <el-table
          ref="multiTable"
          :data="tableData"
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
          border
          v-loading="loading"
        >
          <el-table-column type="selection" width="48" align="center" :reserve-selection="true" />
          <el-table-column label="物料编号" prop="mtrl_no" />
          <el-table-column label="物料图片" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.imge_url">
                <el-image class="vd_elimg" :src="formatPic(scope, 's')" fit="fill"></el-image>
              </span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>
          <el-table-column label="物料名称" prop="mtrl_name" />
          <el-table-column label="规格" prop="mtrl_spec" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="scope.row.mtrl_spec">
                {{ scope.row.mtrl_spec }}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
          <el-table-column label="类型" prop="mtrl_type" :formatter="formatLeavType" />
          <el-table-column label="潘通色号" prop="mtrl_color">
            <template slot-scope="scope">
              <div v-if="scope.row.mtrl_color">
                <div class="vd_dis">
                  <div :class="{ vd_div: scope.row.colr_html }" :style="{ 'background-color': scope.row.colr_html }"></div>
                  <span>{{ scope.row.mtrl_color }}</span>
                </div>
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
          <el-table-column prop="colr_class" label="颜色大类" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.colr_class">
                {{ scope.row.colr_class }}
              </span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>
          <el-table-column label="门幅" prop="mtrl_width">
            <template slot-scope="scope">
              <div v-if="scope.row.mtrl_width">
                {{ scope.row.mtrl_width | formaUnitM }}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
          <el-table-column label="物料厚度" prop="mtrl_thick">
            <template slot-scope="scope">
              <span v-if="scope.row.mtrl_thick">
                {{ scope.row.mtrl_thick | formaUnitH }}
              </span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>
          <el-table-column label="单位" prop="mtrl_unit">
            <template slot-scope="scope">
              <div v-if="scope.row.mtrl_unit">
                {{ scope.row.mtrl_unit }}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
          <el-table-column label="库存状态" :show-overflow-tooltip="true" align="center">
            <template slot-scope="scope">
              <el-tag :type="getStatusName(scope.row.mstk_status).type" size="mini">{{ getStatusName(scope.row.mstk_status).name }}</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <pubPagination ref="pubPagination" :totalPage="totalPage" @changePageSearch="changePageSearch"></pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mstkAPI } from '@api/modules/mstk';
import { optnAPI } from '@api/modules/optn';
import pubPagination from '@/components/common/pubPagination';
import publicTips from '@/views/component/publicTips';
import helper from '@assets/js/helper.js';
export default {
  name: 'SmplEditBom',
  components: {
    pubPagination,
    publicTips
  },
  props: {
    smplForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        mtrl_no: null,
        mtrl_name: null,
        mtrl_type: null,
        mtrl_color: null,
        colr_class: [],
        late_flag: 0
      },
      totalPage: 0,
      btn: {},
      loading: true,
      multiSelection: [],
      currentPage: 1,
      mtrlType: [
        { id: 0, label: '原面料' },
        { id: 1, label: '辅料' },
        { id: 2, label: '包材' }
      ],
      title: '提示:相同数据仅限导入一条',
      optnColorList: []
    };
  },
  created() {
    this.initData();
  },
  filters: {
    formaUnitM(row) {
      return helper.haveFour(row) + '米';
    },
    formaUnitH(row) {
      return helper.reservedDigits(row) + '毫米';
    }
  },
  methods: {
    initData() {
      this.loading = true;
      this.getMtrlsList();
      this.getOptnColor();
    },
    // 获取物料信息
    getMtrlsList() {
      const searchBody = JSON.parse(JSON.stringify(this.searchForm));
      searchBody.page_no = this.currentPage;
      // 添加单据
      searchBody.belo_dept_id = this.smplForm.belo_dept_id;
      // searchBody.status=2;

      let colr_class = null;
      if (this.searchForm.colr_class.length > 0) {
        colr_class = this.searchForm.colr_class.join(',');
      }
      searchBody.colr_class = colr_class;
      get(mstkAPI.getAllMstks, searchBody)
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            this.searchForm.late_flag = 0;
            setTimeout(() => {
              this.loading = false;
            }, 300);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            setTimeout(() => {
              this.loading = false;
            }, 300);
          }
        })
        .catch(() => {
          setTimeout(() => {
            this.loading = false;
          }, 300);
        });
    },
    //获取颜色大类option
    getOptnColor() {
      get(optnAPI.getAllContent, { perm_id: 10010 })
        .then(res => {
          if (res.data.code === 0) {
            this.optnColorList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 删除已选择
    delMulit(val) {
      this.multiSelection.forEach((item, index) => {
        if (item.mstk_id === val) {
          this.$refs.multiTable.toggleRowSelection(this.multiSelection[index]);
        }
      });
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 最近导入
    recentlyImported() {
      this.searchForm.late_flag = 1;
      this.initData();
    },
    // 查询方法
    getMtrlsNow() {
      this.$refs.pubPagination.currentPage = 1;
      this.loading = true;
      this.currentPage = 1;
      this.getMtrlsList();
    },
    // 刷新
    getMtrlsNowO() {
      this.$refs.pubPagination.currentPage = 1;
      this.loading = true;
      this.currentPage = 1;
      (this.searchForm = {
        mtrl_no: null,
        mtrl_name: null,
        mtrl_type: null,
        mtrl_color: null,
        colr_class: [],
        late_flag: 0
      }),
        this.getMtrlsList();
    },
    // 转换合同状态
    getStatusName(status) {
      if (status === 1) {
        return { name: '库存充足', type: 'success' };
      } else if (status === 0) {
        return { name: '库存不足', type: 'warning' };
      }
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.mstk_id;
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getMtrlsList();
    },
    // 物料类型
    formatLeavType(row) {
      if (row.mtrl_type === 0) {
        return '原面料';
      } else if (row.mtrl_type === 1) {
        return '辅料';
      } else if (row.mtrl_type === 2) {
        return '包材';
      }
    },
    // 图片类型
    formatPic(scope, type) {
      let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
      return this.helper.picUrl(scope.row.imge_url, type, updateTime);
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // //新增
    goMtrl() {
      this.$emit('falseBom');
      this.jump(`/mtrl_list?perm_id=${this.helper.retPermId('mtrl')}`);
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('bomChildChanel', false);
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        let temp = [];
        for (let i = 0; i < this.multiSelection.length; i++) {
          let item = {};
          item.mtrl_id = this.multiSelection[i].mtrl_id;
          item.belo_dept_id = this.multiSelection[i].belo_dept_id;
          temp.push(item);
        }
        post(mstkAPI.editMstkUseNum, temp)
          .then(res => {
            if (res.data.code === 0) {
              this.$emit('childBom', this.multiSelection, 1);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
            }
          })
          .catch(() => {});
      } else {
        this.$message({
          message: '至少选择一条信息',
          type: 'warning'
        });
      }
    },
    // 选未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  margin-top: 5px;
  width: 60px;
  height: 60px;
}
.vd_mrt {
  margin-top: 20px;
}
.vd_mar10 {
  margin: 10px 0;
}
.vd_col_font {
  font-size: 16px;
}
.vd_div {
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}
.vd_dis {
  display: flex;
}
.vd_vis {
  visibility: hidden;
}
.vd_mgr2 {
  margin-right: 5px;
  margin-top: 2px;
}
.vd_tag {
  width: 130px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.vd_dis_cen {
  display: flex;
  align-items: center;
}
.vd_font {
  font-size: 20px;
  cursor: pointer;
}
</style>
