<template>
  <el-table :data="smplForm.smpl_part_list" border @selection-change="handleSelectionPart" class="el-table-two">
    <el-table-column type="selection" width="48" align="center"></el-table-column>
    <el-table-column label="序号" width="60" align="center">
      <template slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
    </el-table-column>
    <el-table-column label="配件名称">
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'smpl_part_list.' + scope.$index + '.smpl_part_name'">
          <el-input
            show-word-limit
            maxlength="30"
            v-model="smplForm.smpl_part_list[scope.$index].smpl_part_name"
            placeholder="暂无配件名称"
          ></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="配件规格">
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'smpl_part_list.' + scope.$index + '.smpl_part_spec'">
          <el-input
            v-model="smplForm.smpl_part_list[scope.$index].smpl_part_spec"
            maxlength="255"
            show-word-limit
            placeholder="暂无配件规格"
          >
          </el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="配件价格">
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'smpl_part_list.' + scope.$index + '.smpl_part_price'">
          <el-input
            v-model="smplForm.smpl_part_list[scope.$index].smpl_part_price"
            @input="
              smplForm.smpl_part_list[scope.$index].smpl_part_price = helper.keepTNum1(
                smplForm.smpl_part_list[scope.$index].smpl_part_price
              )
            "
            @blur="
              smplForm.smpl_part_list[scope.$index].smpl_part_price = helper.calcPrice(
                smplForm.smpl_part_list[scope.$index].smpl_part_price,
                4,
                100000000
              )
            "
            maxlength="13"
            show-word-limit
            placeholder="暂无配件价格"
          ></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="配件重量">
      <template slot-scope="scope">
        <el-form-item label-width="0" :prop="'smpl_part_list.' + scope.$index + '.smpl_part_weight'">
          <el-input
            v-model="smplForm.smpl_part_list[scope.$index].smpl_part_weight"
            @input="
              smplForm.smpl_part_list[scope.$index].smpl_part_weight = helper.keepTNum1(
                smplForm.smpl_part_list[scope.$index].smpl_part_weight
              )
            "
            @blur="
              smplForm.smpl_part_list[scope.$index].smpl_part_weight = helper.calcPrice(
                smplForm.smpl_part_list[scope.$index].smpl_part_weight,
                4,
                100000000
              )
            "
            maxlength="13"
            show-word-limit
            placeholder="暂无配件重量"
          ></el-input>
        </el-form-item>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'SmplPartList',
  props: {
    smplForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectionsList: [],
      mtrlTypeGroupOpt: [],
      flag: true
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {},
    //prcs清单选择框
    handleSelectionPart(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit('handleSelectionPart', this.selectionsList);
    }
  }
};
</script>

<style lang="scss">
.vd_dis {
  display: inline-block;
}
.vd_mrl {
  margin-left: 5px;
}
.vd_wid100 {
  width: 100%;
}
.el-input-group__prepend {
  font-size: 16px;
  color: #000000;
}
</style>
