<template>
  <div>
    <div id="container" style="height: 2000px"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'smplEditEcharts',
  props: {
    smplEcharts: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.charts();
  },
  methods: {
    charts() {
      console.log('this', this.smplEcharts);
      var myChart = echarts.init(document.getElementById('container'));
      // 绘制图表
      myChart.setOption({
        title: {
          text: '样品流程图',
          textVerticalAlign: 'top',
          right: '47%',
          textStyle: {
            color: '#494cff'
          }
        },
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove'
        },
        toolbox: {
          show: true,
          left: '70%',
          itemSize: '30',
          feature: {
            saveAsImage: {
              show: true,
              excludeComponents: ['toolbox'],
              pixelRatio: 2
            }
          }
        },
        legend: {
          orient: 'vertical',
          type: 'scroll'
        },
        series: [
          {
            type: 'tree',
            id: 0,
            // name: 'tree1',
            data: [this.smplEcharts],
            top: '10%',
            left: '8%',
            bottom: '22%',
            right: '20%',
            symbolSize: 7,
            edgeShape: 'polyline',
            edgeForkPosition: '63%',
            initialTreeDepth: 3,
            lineStyle: {
              width: 2
            },
            label: {
              backgroundColor: '#fff',
              position: 'left',
              verticalAlign: 'middle',
              align: 'right'
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left'
              }
            },
            emphasis: {
              focus: 'descendant'
            },
            expandAndCollapse: false,
            animationDuration: 550,
            animationDurationUpdate: 750
          }
        ]
      });
      window.onresize = function () {
        myChart.resize();
      };
    }
  }
};
</script>
