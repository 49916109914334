<template>
  <el-table :data="smplForm.smpl_plmtrl_list" border @selection-change="handleSelectionChange2" class="el-table-two">
    <el-table-column type="expand">
      <template v-slot="scope">
        <sub-table :tableData="scope.row.childList"></sub-table>
      </template>
    </el-table-column>
    <el-table-column type="selection" width="48" align="center"></el-table-column>
    <el-table-column label="序号" width="60" align="center">
      <template v-slot="scope">
        {{ scope.$index + 1 }}
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">物料类型</span>
      </template>
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'smpl_plmtrl_list.' + scope.$index + '.smpl_mtrl_type'" :rules="[{ required: true }]">
          <el-select
            @change="val => changePlmtrlOption(val, scope.$index)"
            filterable
            v-model="smplForm.smpl_plmtrl_list[scope.$index].smpl_mtrl_type"
            placeholder="请选择物料类型"
            size="small"
          >
            <el-option
              v-for="item in [
                { label: '原面料', id: 0 },
                { label: '辅料', id: 1 }
              ]"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">所用物料</span>
      </template>
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'smpl_plmtrl_list.' + scope.$index + '.smpl_parent_ids'" :rules="[{ required: true }]">
          <div class="vd_dis">
            <el-tag
              v-for="tag in smplForm.smpl_plmtrl_list[scope.$index].smpl_parent_listO"
              :key="tag.key"
              class="vd_magr3"
              :closable="!isShow"
              @close="handleClose(scope, tag)"
              type="success"
            >
              {{ tag.mtrl_no }}
            </el-tag>
            <el-popover placement="bottom" width="300" :disabled="isShow" trigger="click">
              <el-tag
                v-for="tag in selectPlmtrlOption"
                :key="tag.smpl_mtrl_id"
                class="vg_cursor vd_magr3"
                @click="plmtrlChange(scope, tag)"
                type="info"
              >
                {{ tag.mtrl_no }}({{ tag.mtrl_name }})
              </el-tag>
              <el-button
                slot="reference"
                plian
                :disabled="isShow"
                @click="addDefaultList(scope.$index)"
                class="el-icon-plus vg_pointer vd_clire"
              ></el-button>
              <!-- <el-button icon="el-icon-plus vd_clire" plian></el-button> -->
            </el-popover>
          </div>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column>
      <template slot="header">
        <span class="vg_deep_red">加工类型</span>
      </template>
      <template v-slot="scope">
        <div style="text-align: center">
          <el-link @click="compListChange(scope, 0)" :disabled="isShow" type="primary" class="vg_cursor vg_mr_8">F</el-link>
          <el-link :disabled="isShow" type="primary" class="vg_cursor vg_mr_8"></el-link>
          <el-link :disabled="isShow" type="primary" class="vg_cursor vg_mr_8"></el-link>
          <el-link @click="compListChange(scope, 1)" :disabled="isShow" type="primary" class="vg_cursor vg_mr_8">Y</el-link>
          <!--        <el-link @click="compListChange(scope, 2)" :disabled="isShow" type="primary" class="vg_cursor">电绣</el-link>-->
        </div>
      </template>
    </el-table-column>
    <el-table-column width="200px">
      <template slot="header">
        <span class="vg_deep_red">物料编号</span>
      </template>
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'smpl_plmtrl_list.' + scope.$index + '.mtrl_no'" :rules="[{ required: true }]">
          <el-input
            @change="plmtrlChangeCut(scope)"
            v-model="smplForm.smpl_plmtrl_list[scope.$index].mtrl_no"
            @input="smplForm.smpl_plmtrl_list[scope.$index].mtrl_no = helper.keepEngNum2(smplForm.smpl_plmtrl_list[scope.$index].mtrl_no)"
            maxlength="30"
            show-word-limit
            placeholder="暂无物料编号"
          ></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="物料名称" width="200">
      <template slot="header">
        <span class="vg_deep_red">物料名称</span>
      </template>
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'smpl_plmtrl_list.' + scope.$index + '.mtrl_name'" :rules="[{ required: true }]">
          <el-tooltip class="item" effect="dark" :content="smplForm.smpl_plmtrl_list[scope.$index].mtrl_name" placement="top">
            <el-input
              v-model="smplForm.smpl_plmtrl_list[scope.$index].mtrl_name"
              maxlength="50"
              show-word-limit
              placeholder="暂无物料名称"
              clearable
            ></el-input>
          </el-tooltip>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="物料单位" width="80">
      <template v-slot="scope">
        <el-form-item label-width="1000" :prop="'smpl_plmtrl_list.' + scope.$index + '.mtrl_unit'">
          <!-- <el-select v-model="smplForm.smpl_plmtrl_list[scope.$index].mtrl_unit" placeholder="请选择单位"  clearable>
            <el-option
              v-for="item in mtrlTypeGroupOpt"
              :key="item.id"
              :label="item.param1"
              :value="item.param1">
            </el-option>
          </el-select> -->
          <el-input disabled v-model="mtrlNuit" maxlength="30" show-word-limit placeholder="暂无物料单位"></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="用量">
      <template slot="header">
        <span class="vg_deep_red">用量</span>
      </template>
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'smpl_plmtrl_list.' + scope.$index + '.smpl_mtrl_consume'" :rules="[{ required: true }]">
          <el-input
            @change="totalPriceChange(scope.$index)"
            @blur="
              smplForm.smpl_plmtrl_list[scope.$index].smpl_mtrl_consume = helper.calcPrice(
                smplForm.smpl_plmtrl_list[scope.$index].smpl_mtrl_consume,
                4,
                10000
              )
            "
            @input="
              smplForm.smpl_plmtrl_list[scope.$index].smpl_mtrl_consume = helper.keepTNum1(
                smplForm.smpl_plmtrl_list[scope.$index].smpl_mtrl_consume
              )
            "
            v-model="smplForm.smpl_plmtrl_list[scope.$index].smpl_mtrl_consume"
            maxlength="9"
            show-word-limit
            placeholder="暂无用量"
          ></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column label="单价">
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'smpl_plmtrl_list.' + scope.$index + '.mtrl_prod_price'">
          <el-input
            show-word-limit
            maxlength="11"
            v-model="smplForm.smpl_plmtrl_list[scope.$index].mtrl_prod_price"
            @input="
              smplForm.smpl_plmtrl_list[scope.$index].mtrl_prod_price = helper.keepTNum1(
                smplForm.smpl_plmtrl_list[scope.$index].mtrl_prod_price
              )
            "
            @change="priceChange(scope)"
            placeholder="暂无单价"
          ></el-input>
        </el-form-item>
      </template>
    </el-table-column>
    <!-- <el-table-column label="损耗比率">
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'smpl_plmtrl_list.'+scope.$index+'.smpl_mtrl_loss'">
          <el-input @change="lossChange(smplForm.smpl_plmtrl_list[scope.$index].smpl_mtrl_loss,scope.$index)" @input="smplForm.smpl_plmtrl_list[scope.$index].smpl_mtrl_loss =helper.keepTNum1(smplForm.smpl_plmtrl_list[scope.$index].smpl_mtrl_loss )
                " v-model="smplForm.smpl_plmtrl_list[scope.$index].smpl_mtrl_loss" maxlength="30" show-word-limit placeholder="暂无损耗比率"></el-input>
        </el-form-item>
      </template>
    </el-table-column> -->
    <el-table-column label="工艺费用" width="200">
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'smpl_plmtrl_list.' + scope.$index + '.smpl_comp_addcost'">
          <el-row>
            <el-col :md="8"><span>复合费用</span></el-col>
            <el-col :md="16">
              <el-input
                :disabled="smplForm.smpl_plmtrl_list[scope.$index].compFlag"
                @change="priceChange1(scope)"
                @input="
                  smplForm.smpl_plmtrl_list[scope.$index].smpl_comp_addcost = helper.keepTNum1(
                    smplForm.smpl_plmtrl_list[scope.$index].smpl_comp_addcost
                  )
                "
                v-model="smplForm.smpl_plmtrl_list[scope.$index].smpl_comp_addcost"
                maxlength="7"
                show-word-limit
                placeholder="暂无复合费用"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label-width="0" :prop="'smpl_plmtrl_list.' + scope.$index + '.smpl_prit_addcost'">
          <el-row>
            <el-col :md="8"><span>印花费用</span></el-col>
            <el-col :md="16">
              <el-input
                :disabled="smplForm.smpl_plmtrl_list[scope.$index].pritFlag"
                @change="priceChange2(scope)"
                @input="
                  smplForm.smpl_plmtrl_list[scope.$index].smpl_prit_addcost = helper.keepTNum1(
                    smplForm.smpl_plmtrl_list[scope.$index].smpl_prit_addcost
                  )
                "
                v-model="smplForm.smpl_plmtrl_list[scope.$index].smpl_prit_addcost"
                maxlength="7"
                show-word-limit
                placeholder="暂无印花费用"
              ></el-input>
            </el-col>
          </el-row>
        </el-form-item>
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip label="金额">
      <template v-slot="scope">
        <el-form-item label-width="0" :prop="'smpl_plmtrl_list.' + scope.$index + '.totalChange'">
          <el-input
            disabled
            show-word-limit
            maxlength="9"
            v-model="smplForm.smpl_plmtrl_list[scope.$index].totalChange"
            placeholder="暂无金额"
          ></el-input>
        </el-form-item>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { get, post } from '@api/request';
import { mtrlAPI } from '@api/modules/mtrl';
import SubTable from '@/views/DevelopManagement/SmplManage/TabChild/Componet/TabPanel/subTable/subTable.vue';

export default {
  name: 'SmplEditPrcsChild',
  components: { SubTable },
  props: {
    smplForm: {
      type: Object,
      required: true
    },
    mtrlTypeGroupOpt: {
      type: Array,
      required: true
    },
    plmtrlShow: {
      type: Boolean,
      required: true
    },
    isShow: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      selectPlmtrlOption: [],
      selectionsList: [],
      flag: true,
      compList: ['复合', '印花', '电绣'],
      show: false,
      mtrlNuit: '米'
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {},
    compListChange(val, num) {
      this.$emit('compListChange', val, num);
    },
    // 删除
    handleClose(scope, val) {
      if (!this.isShow) {
        this.$emit('deletePlmtrl', scope, val);
      }
    },
    changePlmtrlOption(val, index) {
      this.selectPlmtrlOption = this.smplForm.smpl_plmtrl_list[index].plmtrlOption.filter(x => x.mtrl_type === val);
    },
    // 计算总金额
    totalPriceChange(scope) {
      this.$emit('priceChange', scope);
    },
    addDefaultList(index) {
      this.selectPlmtrlOption = [];
      this.selectPlmtrlOption = Array.from(
        this.smplForm.smpl_mtrl_list,
        ({ mtrl_name, mtrl_no, mtrl_prod_price, mtrl_spec, mtrl_type, smpl_mtrl_id }) => ({
          mtrl_name: mtrl_name,
          mtrl_no: mtrl_no,
          mtrl_prod_price: mtrl_prod_price,
          mtrl_spec: mtrl_spec,
          mtrl_type: mtrl_type,
          smpl_mtrl_id: smpl_mtrl_id
        })
      ).filter(({ mtrl_type }) => mtrl_type === this.smplForm.smpl_plmtrl_list[index].smpl_mtrl_type);
    },
    // 下拉框回显
    plmtrlChange(scope, val) {
      if (!this.isShow) {
        this.flag = false;
        this.$emit('plmtrlChangeIds', scope, val);
        this.$nextTick(() => {
          this.flag = true;
        });
      }
    },
    // 选择框输入值改变裁片选择
    plmtrlChangeCut(scope) {
      let temp = 0;
      get(mtrlAPI.judgeMtrlNo, { mtrl_no: scope.row.mtrl_no })
        .then(res => {
          if (res.data.code === 0) {
            for (let i = 0; i < this.smplForm.smpl_plmtrl_list.length; i++) {
              if (this.smplForm.smpl_plmtrl_list[i].mtrl_no === scope.row.mtrl_no) {
                temp += 1;
              }
            }
            if (temp > 1) {
              scope.row.mtrl_no = null;
              this.$message.error('物料编号重复，请重新填写！');
            } else {
              this.$emit('plmtrlChangeCut', scope);
            }
          } else {
            scope.row.mtrl_no = null;
            this.$message.error('物料编号重复，请重新填写！');
            this.$emit('plmtrlShowChange');
          }
        })
        .catch(() => {});
    },
    priceChange(scope) {
      scope.row.mtrl_prod_price = this.helper.calcPrice(scope.row.mtrl_prod_price, 4, 1000000);
      this.totalPriceChange(scope.$index);
    },
    priceChange1(scope) {
      if (this.smplForm.smpl_plmtrl_list[scope.$index].smpl_comp_addcost) {
        scope.row.smpl_comp_addcost = this.helper.calcPrice(scope.row.smpl_comp_addcost, 2, 100);
      } else {
        scope.row.smpl_comp_addcost = '0.0000';
      }
      this.totalPriceChange(scope.$index);
    },
    priceChange2(scope) {
      if (this.smplForm.smpl_plmtrl_list[scope.$index].smpl_prit_addcost) {
        scope.row.smpl_prit_addcost = this.helper.calcPrice(scope.row.smpl_prit_addcost, 2, 100);
      } else {
        scope.row.smpl_prit_addcost = '0.0000';
      }
      this.totalPriceChange(scope.$index);
    },
    //prcs清单选择框
    handleSelectionChange2(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
      this.$emit('handleSelectionChange2', this.selectionsList);
    }
  }
};
</script>

<style lang="scss">
.vd_dis {
  display: inline-block;
}
.vd_mrl {
  margin-left: 5px;
}
.vd_wid100 {
  width: 100%;
}
.vd_font20 {
  margin-top: 5px;
  font-size: 20px;
}
.vd_clire {
  background-color: #c4bcbc;
  border-radius: 50%;
  padding: 2px !important;
  border: 0;
  color: #fff;
}
.vd_dis {
  width: 100%;
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
}
.vd_magr3 {
  margin-right: 3px;
}
.vd_dis_flex {
  display: flex;
}
</style>
