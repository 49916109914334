<template>
  <div>
    <el-popover ref="popover" placement="right" title="" width="500" trigger="hover">
      <img class="vd_popimg" :src="imgeUrlO" />
    </el-popover>
    <div v-popover:popover>
      <img :src="imgeUrl" class="avatar" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    imgeUrl: { type: String, required: true },
    imgeUrlO: { type: String, required: true }
  }
};
</script>
<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.vd_popimg {
  width: 500px;
  height: 500px;
}
.avatar {
  width: 100% !important;
  height: 100% !important;
}
.avatar div {
  width: 100% !important;
  height: 100% !important;
}
.vd_popimg {
  width: 500px;
  height: 500px;
}
</style>
